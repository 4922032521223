import React, { useRef } from 'react';

import * as styles from './styles.module.scss';
import CTA from '../CTA';
import productGif from '../../images/deploy-kafka.avif';
import { useActivePath, useMobileView, useSiteMetadata } from '../../hooks';

const ProductLanding = () => {
  const data = useSiteMetadata();
  const ref = useRef<HTMLElement>(null);
  useActivePath('/#products', ref);
  const isMobileView = useMobileView();
  return (
    <section className={styles.root} id="products" ref={ref}>
      <h2 className={styles.title}>Spin Up Kafka Instances In Minutes For Free</h2>
      <div className={styles.container}>
        <div className={styles.productImage}>
          <img src={productGif} alt="deploy-managed-kafka" height='100%' width='auto' />
        </div>
        <div className={styles.content}>
          <p className={styles.description}>
          Unlock the full potential of Kafka—one of the most versatile event streaming platforms—through our fully managed solution at Bitnimbus.io. Experience our fast, secure, and affordable data streaming service ready in just a few clicks. 
          <br/><br/>
          Sign up today, no credit card required.

          </p>
          <div className={styles.listContainer}>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                Choose the size that suits your budget and product needs
              </li>
              <li className={styles.listItem}>
                Deploy your instances with a click of button
              </li>
              <li className={styles.listItem}>
                Easy to navigate and to manage all your workloads
              </li>
              <li className={styles.listItem}>
                24x7 support to ensure your workloads is always up and running
              </li>
            </ul>
          </div>
          <div className={styles.linkContainer}>
            <div style={{ marginRight: '1rem' }}>
              <CTA
                type="primary"
                label="Free Trial"
                external={true}
                link={data.site.siteMetadata.productLink}
              />
            </div>
            <CTA
              type="default"
              label="Schedule Demo >"
              external={false}
              link="#contact-us"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductLanding;

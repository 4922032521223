import React, {
  ChangeEvent,
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import axios, { AxiosError } from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import CTA from '../CTA';
import TextField from '../TextField';
import TextArea from '../TextArea';
import Dropdown from '../Dropdown';
import * as styles from './styles.module.scss';
import { useActivePath } from '../../hooks';
import { useMediaQuery } from 'react-responsive';
import Button from '../Button';
import Container from '../Container';
import { themeContext } from '../../context';
import CaptchaVerification from '../CaptchaVerification';

interface Fields {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  request_type: string;
  query: string;
}

const ContactUs = () => {
  const compactView = useMediaQuery({
    query: '(max-width: 360px)',
  });
  const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    request_type: 'Pricing',
    query: '',
  };
  const [fields, setFields] = useState<Fields>({
    ...initialState,
  });
  const [helperText, setHelperText] = useState<{ [key: string]: string }>({});
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const themeCtx = useContext(themeContext);
  const ref = useRef(null);
  useActivePath('/#contact-us', ref);

  useEffect(() => {
    setEnableSubmit(captchaCompleted);
  }, [captchaCompleted]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
    setHelperText({
      ...helperText,
      [e.target.name]: '',
    });
  };

  const handleCaptchaComplete = (token: string) => {
    setCaptchaToken(token);
    setCaptchaCompleted(true);
  };

  const validateForm = (userData: Fields) => {
    const { first_name, last_name, phone, email } = userData;
    const errObj: { [key: string]: string } = {};
    const NAME_REGEX = /^[a-zA-Z]{3,50}$/;
    if (first_name.length === 0) {
      errObj.first_name = 'First Name should be provided';
    } else if (!first_name.match(NAME_REGEX)) {
      errObj.first_name =
        'First Name should have only letters with minimum of 3 to maximum of 50 characters';
    }
    if (last_name.length === 0) {
      errObj.last_name = 'Last Name should be provided';
    } else if (!last_name.match(NAME_REGEX)) {
      errObj.last_name =
        'Last Name should have only letters with minimum of 3 to maximum of 50 characters';
    }
    if (email.length === 0) {
      errObj.email = 'Email Name should be provided';
    } else if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      errObj.email = 'Should provide a valid email address';
    }
    if (phone && !phone.match(/^\+\d{10,15}/)) {
      errObj.phone = 'Should provide a valid phone number with country code';
    }

    return errObj;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setResetCaptcha(false);
    const errors = validateForm(fields);
    if (Object.keys(errors).length > 0) {
      setHelperText({
        ...errors,
      });
      return;
    }
    try {
      const res = await axios.post(
        process.env.API_ENDPOINT_URL || 'http://localhost:9000',
        {
          token: captchaToken,
          ...fields,
        }
      );
      setFields({
        ...initialState,
      });
      toast.success(
        'Thank you for contacting us. We will reach out to you soon!'
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err.response?.data.message || 'something went wrong');
      }
    } finally {
      setResetCaptcha(true);
      setCaptchaCompleted(false);
    }
  };

  return (
    <section id="contact-us" ref={ref}>
      <Container className={`${styles.root} ${styles.theme}`}>
        <div className={styles.content} data-theme="dark">
          <h2 className={styles.title}>We'd love to hear from you</h2>
          <p className={styles.caption}>
            Fill out the form below and our team will contact you soon
          </p>
          <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <TextField
                value={fields?.first_name}
                label="First Name"
                id="first_name"
                onChange={handleChange}
                helperText={helperText.first_name}
              />
              <TextField
                value={fields?.last_name}
                label=" Last Name"
                id="last_name"
                onChange={handleChange}
                helperText={helperText.last_name}
              />
              <TextField
                value={fields?.email}
                label="Email"
                id="email"
                onChange={handleChange}
                helperText={helperText.email}
              />
              <TextField
                value={fields.phone}
                label="Phone Number (Optional)"
                id="phone"
                placeholder="+15555551234"
                onChange={handleChange}
                helperText={helperText.phone}
              />
              <Dropdown
                label="Request Type"
                options={['Pricing', 'Request Demo', 'Others']}
                id="request_type"
                value={fields?.request_type}
                onChange={handleChange}
              />
              <TextArea
                value={fields?.query}
                label="How Can We Help?"
                id="query"
                onChange={handleChange}
              />
              <div>
                <CaptchaVerification onSuccess={handleCaptchaComplete} resetCaptcha={resetCaptcha} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  disabled={!enableSubmit}
                  type="submit"
                  variant="primary"
                  label="Submit"
                />
              </div>
            </form>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeButton={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="light"
            style={{
              width: 'fit-content',
              padding: '2rem',
              fontSize: '0.75rem',
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
